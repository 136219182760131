import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxFlagIconCssModule } from 'ngx-flag-icon-css';
import { SharedEffects } from './state/shared.effects';
import { reducer } from './state/shared.reducer';
import { HourComponent } from './_components/hour/hour.component';
import { AmountInputComponent } from './_components/amount-input/amount-input.component';
import { TimesheetAttachmentsComponent } from './_components/attachments/attachments.component';
import { ConfirmDeleteComponent } from './_components/confirm-delete/confirm-delete.component';
import { ConfirmLeaveComponent } from './_components/confirm-leave/confirm-leave.component';
import { ErrorComponent } from './_components/error/error.component';
import { FileAttachmentComponent } from './_components/file-attachment/file-attachment.component';
import { FileInputComponent } from './_components/file-input/file-input.component';
import { FilterComponent } from './_components/filter/filter.component';
import { FlexworkerInfoComponent } from './_components/flexworker-info/flexworker-info.component';
import { ImageUploaderComponent } from './_components/image-uploader/image-uploader.component';
import { LoadingComponent } from './_components/loading/loading.component';
import { LogoHeaderComponent } from './_components/logo-header/logo-header.component';
import { PageNotFoundComponent } from './_components/page-not-found/page-not-found.component';
import { SpinButtonComponent } from './_components/spin-button/spin-button.component';
import { SpinnerButtonComponent } from './_components/spinner-button/spinner-button.component';
import { TimeInputComponent } from './_components/time-input/time-input.component';
import { TimesheetCommentComponent } from './_components/timesheet-comment/timesheet-comment.component';
import { ErrorShellComponent } from './_containers/error-shell/error-shell.component';
import { DropdownComponent } from './_components/dropdown/dropdown.component'
import { MultiDropdownComponent } from './_components/multi-dropdown/multi-dropdown.component'
import { TextAreaAutogrowDirective } from './_directives/textarea-autogrow.directive';
import { CapitalizeFirstPipe } from './_pipes/capitalize-first.pipe';
import { DateProxyPipe } from './_pipes/date-proxy.pipe';
import { LeftPipe } from './_pipes/left.pipe';
import { ReplacePipe } from './_pipes/replace.pipe';
import { SafeHtmlPipe } from './_pipes/safe-html.pipe';
import { LucideAngularModule, ChevronRight, History, Divide, ShoppingCart, Loader, Search, TriangleAlert, Circle, Captions, Factory, Pencil, Trash2 } from 'lucide-angular';
import { LucideFillDirective } from './_directives/lucide-fill.directive';
@NgModule({
    declarations: [
        LeftPipe,
        ReplacePipe,
        CapitalizeFirstPipe,
        SafeHtmlPipe,
        DateProxyPipe,
        HourComponent,
        TextAreaAutogrowDirective,
        PageNotFoundComponent,
        LoadingComponent,
        ConfirmLeaveComponent,
        ConfirmDeleteComponent,
        LogoHeaderComponent,
        SpinButtonComponent,
        SpinnerButtonComponent,
        TimeInputComponent,
        AmountInputComponent,
        FileInputComponent,
        FileAttachmentComponent,
        FilterComponent,
        ImageUploaderComponent,
        ErrorComponent,
        ErrorShellComponent,
        TimesheetAttachmentsComponent,
        TimesheetCommentComponent,
        FlexworkerInfoComponent,
        DropdownComponent,
        MultiDropdownComponent,
        LucideFillDirective
    ],
    imports: [
        LucideAngularModule.pick({ ChevronRight, History, Divide, ShoppingCart, Loader, Search, TriangleAlert, Circle, Captions, Factory, Pencil, Trash2 }),
        StoreModule.forFeature('shared', reducer),
        EffectsModule.forFeature([SharedEffects]),
        CommonModule,
        RouterModule,
        ModalModule.forRoot(),
        TranslateModule,
        FormsModule,
        NgxFlagIconCssModule,
        BsDropdownModule
    ],
    exports: [
        CommonModule,
        RouterModule,
        FormsModule,
        BsDropdownModule,
        LeftPipe,
        ReplacePipe,
        CapitalizeFirstPipe,
        SafeHtmlPipe,
        DateProxyPipe,
        TextAreaAutogrowDirective,
        PageNotFoundComponent,
        ReactiveFormsModule,
        LoadingComponent,
        HourComponent,
        TranslateModule,
        LogoHeaderComponent,
        SpinButtonComponent,
        SpinnerButtonComponent,
        TimeInputComponent,
        AmountInputComponent,
        FileInputComponent,
        FileAttachmentComponent,
        FilterComponent,
        ImageUploaderComponent,
        FontAwesomeModule,
        TimesheetAttachmentsComponent,
        TimesheetCommentComponent,
        FlexworkerInfoComponent,
        NgxFlagIconCssModule,
        DropdownComponent,
        MultiDropdownComponent,
        LucideFillDirective
    ]
})
export class SharedModule { }
