import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeHu from '@angular/common/locales/hu';
import localeNl from '@angular/common/locales/nl';
import localePl from '@angular/common/locales/pl';
import localeRo from '@angular/common/locales/ro';
import localeUk from '@angular/common/locales/uk';
import localeRu from '@angular/common/locales/ru';
import localeLv from '@angular/common/locales/lv';
import { APP_INITIALIZER, ErrorHandler, Injectable, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Elmahio from 'elmah.io.javascript';
import { ToastrModule } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { AppComponent } from './app.component';
import { AuthGuard } from './auth/_guards';
import { IsAdminGuard } from './auth/_guards/is-admin.guard';
import { IsEmployeeGuard } from './auth/_guards/is-employee.guard';
import { IsFeatureFlagEnabledGuard } from './auth/_guards/is-feature-flag-enabled.guard';
import { IsFlexworkerGuard } from './auth/_guards/is-flexworker.guard';
import { ErrorInterceptor, JwtInterceptor } from './auth/_helpers';
import { EmployeeFacilitiesDashboardModule } from './employee-facilities-dashboard/employee-facilities-dashboard.module';
import { FrameModule } from './frame/frame.module';
import { NoAccessComponent } from './no-access/no-access.component';
import { ApiModule, BASE_PATH } from './rest';
import { SharedModule } from './shared/shared.module';
import { ConfirmLeaveGuard } from './shared/_components/confirm-leave/confirm-leave.guard';
import { PageNotFoundComponent } from './shared/_components/page-not-found/page-not-found.component';
import { ErrorShellComponent } from './shared/_containers/error-shell/error-shell.component';
import { ClientConfigService } from './shared/_services/client-config.service';
import { DynamicLocaleId } from './shared/_services/dynamic-locale-id.service';
import { AppInsightsMonitoringService } from './shared/_services/monitoring.service';
import { AppEffects } from './state/app.effects';
import { reducer } from './state/app.reducer';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LucideAngularModule, Loader, PenTool, Clock, SlidersHorizontal, Home, Calendar, CalendarDays, Banknote, Mail, CircleHelp, LogOut, GitBranch, TriangleAlert } from 'lucide-angular';

registerLocaleData(localeNl);
registerLocaleData(localeDe);
registerLocaleData(localeHu);
registerLocaleData(localePl);
registerLocaleData(localeRo);
registerLocaleData(localeUk);
registerLocaleData(localeRu);
registerLocaleData(localeLv);

@Injectable()
export class ErrorHandlerService implements ErrorHandler {
    logger: any = null;
    monitoringService: AppInsightsMonitoringService;

    constructor(injector: Injector) {
        if (environment.production) {
            this.logger = new Elmahio({
                apiKey: environment.ELMAH_API_KEY,
                logId: environment.ELMAH_LOG_ID,
                breadcrumbs: true,
            });

            this.monitoringService = injector.get<AppInsightsMonitoringService>(AppInsightsMonitoringService);
        }
    }

    handleError(error) {
        if (environment.production) {
            if (error && error.message) {
                this.logger.error(error.message, error);
            } else {
                this.logger.error('Error in application', error);
            }

            this.monitoringService.logException(error);
        } else {
            console.error(error);
        }
    }
}

@NgModule({
    declarations: [AppComponent, NoAccessComponent],
    imports: [
        BrowserModule,
        HttpClientModule,
        RouterModule.forRoot(
            [
                {
                    path: '',
                    redirectTo: 'auth/login',
                    pathMatch: 'full',
                },
                {
                    path: 'no-access',
                    canActivate: [AuthGuard],
                    pathMatch: 'full',
                    component: NoAccessComponent,
                },
                {
                    path: 'nl',
                    canActivate: [AuthGuard, IsFeatureFlagEnabledGuard],
                    loadChildren: () => import('./embedded-nl-portal/embedded-nl-portal.module').then((m) => m.EmbeddedNlPortalModule),
                },
                {
                    path: 'auth',
                    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
                },
                {
                    path: 'handletoken/:token',
                    pathMatch: 'full',
                    redirectTo: 'auth/handletoken/:token',
                },
                {
                    path: 'login/ChangePasswordForm.aspx',
                    pathMatch: 'full',
                    redirectTo: 'auth/update-password',
                },
                {
                    path: 'login',
                    pathMatch: 'full',
                    redirectTo: 'auth/login',
                },
                {
                    path: 'organisation-portal',
                    loadChildren: () => import('./organisation-portal/organisation-portal.module').then((m) => m.OrganisationPortalModule),
                },
                {
                    path: 'registration-portal',
                    loadChildren: () => import('./registration-portal/registration-portal.module').then((m) => m.RegistrationPortalModule),
                },
                {
                    path: 'flexworker-portal',
                    loadChildren: () => import('./flexworker-portal/flexworker-portal.module').then((m) => m.FlexworkerPortalModule),
                },
                {
                    path: 'themes',
                    loadChildren: () => import('./themes/themes.module').then((m) => m.ThemesModule),
                    canActivate: [AuthGuard, IsEmployeeGuard],
                },
                {
                    path: 'settings',
                    loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
                    canActivate: [AuthGuard, IsAdminGuard],
                },
                {
                    path: 'timesheets',
                    canActivate: [AuthGuard, IsFeatureFlagEnabledGuard],
                    loadChildren: () => import('./timesheets/timesheets.module').then((m) => m.TimesheetsModule),
                },
                {
                    path: 'employee-facilities',
                    loadChildren: () =>
                        import('./employee-facilities-dashboard/employee-facilities-dashboard.module').then(
                            (m) => m.EmployeeFacilitiesDashboardModule
                        ),
                },
                {
                    path: 'requests',
                    canActivate: [AuthGuard, IsEmployeeGuard],
                    loadChildren: () => import('./requests/requests.module').then((m) => m.RequestsModule),
                },
                {
                    path: 'shifts-master-data',
                    // canActivate: [IsFeatureFlagEnabledGuard],
                    canActivate: [AuthGuard, IsEmployeeGuard],
                    loadChildren: () => import('./shifts-master-data/shifts-master-data.module').then((m) => m.ShiftsMasterDataModule),
                },
                {
                    path: 'worksheets',
                    // canActivate: [IsFeatureFlagEnabledGuard],
                    loadChildren: () => import('./worksheets/worksheets.module').then((m) => m.WorksheetsModule),
                },
                { path: 'error', component: ErrorShellComponent },
                { path: '**', component: PageNotFoundComponent },
            ],
            { relativeLinkResolution: 'corrected' }
        ),
        FrameModule,
        SharedModule,
        FormsModule,
        ApiModule,
        BrowserAnimationsModule, // required animations module
        ToastrModule.forRoot({
            positionClass: 'toast-top-right',
            closeButton: true,
        }), // ToastrModule added
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        StoreModule.forRoot({ app: reducer }),
        EffectsModule.forRoot([AppEffects]),
        // Instrumentation must be imported after importing StoreModule (config is optional)
        StoreDevtoolsModule.instrument({
            name: 'Flexportal',
            maxAge: 25,
            logOnly: environment.production,
        }),
        EmployeeFacilitiesDashboardModule,
        FontAwesomeModule,
        LucideAngularModule.pick({
            Loader,
            TriangleAlert,
            PenTool,
            Clock,
            SlidersHorizontal,
            Home,
            Calendar,
            CalendarDays,
            Banknote,
            Mail,
            CircleHelp,
            LogOut,
            GitBranch
        }),
    ],
    bootstrap: [AppComponent],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: HttpMockRequestInterceptor,
        //     multi: true,
        // },
        {
            provide: BASE_PATH,
            useFactory: () => {
                return environment.production ? `https://${window.location.host}` : `${environment.BASE_PATH}`;
            },
        },
        { provide: ErrorHandler, useClass: ErrorHandlerService },
        { provide: LOCALE_ID, useClass: DynamicLocaleId, deps: [TranslateService] },
        ConfirmLeaveGuard,
        AuthGuard,
        IsFlexworkerGuard,
        IsFeatureFlagEnabledGuard,
        ClientConfigService,
        AppInsightsMonitoringService,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeClientConfig,
            deps: [ClientConfigService],
            multi: true,
        },
    ],
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
    // Mark the translation ids here. So they are not dismissed.
    _('shared.messages.external-systems-fail');
    _('shared.messages.unauthorized');
    _('shared.messages.page-not-available');
    _('shared.messages.no-changes-where-made');
    _('auth.login.user-account-blocked');
    _('auth.login.no-token-found');
    _('auth.login.user-account-not-flexworker-or-employee');
    _('auth.login.multiple-user-accounts-found');
    _('auth.login.no-user-account-found');
    _('flexworker-portal.travel-statement-lines.status.DENIED');
    _('flexworker-portal.travel-statement-lines.status.WAITING');
    _('flexworker-portal.travel-statement-lines.status.APPROVED');
    _('settings.app-themes.general');
    _('shared.messages.tenant-not-found');
    _('frame.menu.new-travel-statement');
    _('frame.menu.sign-document');
    _('frame.menu.request');
    _('errors.configuration.admin');
    _('errors.configuration.employee');
    _('timesheets.timesheet');
    _('shared.days');
    _('shared.hours');
    _('shared.later');
    _('domain.absence.status-enum.approved');
    _('domain.absence.status-enum.resignedwhilesick');
    _('domain.absence.status-enum.safetynet');
    _('domain.absence.status-enum.waiting');
    _('domain.absence.status-enum.denied');
    _('domain.absence.reason-enum.incorrectillnessreport');

    return new TranslateHttpLoader(http);
}

function initializeClientConfig(clientConfigService: ClientConfigService) {
    return () => {
        clientConfigService.load();
    };
}
