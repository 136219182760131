<h5 style="font-weight: normal;" data-cy="userFullName" class="name">
    <span class="impersonated" *ngIf="impersonatedByFullName" (click)="onShowImpersonatedBy()"
        [title]="fullName + ' (via ' + impersonatedByFullName + ')'">
        <i-lucide name="venetian-mask" size="24" strokeWidth="2"></i-lucide>

        <span class="full-name" *ngIf="!showImpersonatedBy">
            {{ fullName }}
        </span>
        <span class="full-name" *ngIf="showImpersonatedBy">
            Via: {{ impersonatedByFullName }}
        </span>
    </span>

    <span class="full-name" *ngIf="!impersonatedByFullName" [title]="fullName">
        {{ fullName }}
    </span>
</h5>
<a [routerLink]="['/auth/account-settings']" class="language">
    <i-lucide name="message-circle" size="16" strokeWidth="2"></i-lucide>
    {{ 'auth.account-settings.languages.' + language | translate
    }}
</a>