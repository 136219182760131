import { NgModule } from '@angular/core';
import { ThemeComponent } from './components/theme/theme.component';
import { RouterModule } from '@angular/router';
import { ConfirmLeaveGuard } from '../shared/_components/confirm-leave/confirm-leave.guard';
import { SharedModule } from '../shared/shared.module';
import { reducer } from './state/themes.reducer';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ThemesEffects } from './state/themes.effects';
import { ThemeShellComponent } from './containers/theme-shell/theme-shell.component';
import { LucideAngularModule, Loader, Save, PlusCircle, MinusCircle } from 'lucide-angular';
@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature('themes', reducer),
        EffectsModule.forFeature([ThemesEffects]),
        LucideAngularModule.pick({ Loader, Save, PlusCircle, MinusCircle }),
        RouterModule.forChild([
            {
                path: 'theme',
                component: ThemeShellComponent,
                canDeactivate: [ConfirmLeaveGuard],
            },
        ]),
    ],
    declarations: [ThemeComponent, ThemeShellComponent],
})
export class ThemesModule { }
