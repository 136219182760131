<nav class="navbar navbar-light">
    <div id="navbarText">
        <ul class="navbar-nav mr-auto mt-2 mb-4" *ngFor="let ms of menu">
            <span *ngIf="ms.showText" style="font-weight: 500; margin-bottom: 8px;">
                <i-lucide [name]="ms.lucideIcon" size="16" strokeWidth="2" class="mr-1"></i-lucide>
                {{ ms.text | translate }}
            </span>
            <div>
                <li class="nav-item" *ngFor="let mi of ms.menuItems">
                    <a class="nav-item nav-link" style="margin-left: 28px;" [routerLink]="[mi.routerLink]"
                        routerLinkActive="active">{{
                        mi.text | translate
                        }}</a>
                </li>
            </div>
        </ul>
        <ul class="navbar-nav mr-auto mt-4">
            <li class="nav-item" class="pointer" *ngIf="isFlexworker || isContact">
                <a class="nav-item nav-link"
                    [routerLink]="isFlexworker ? '/flexworker-portal/contact' : '/organisation-portal/contact'"
                    routerLinkActive="active">
                    <i-lucide name="mail" size="16" strokeWidth="1" class="mr-1"></i-lucide>
                    {{ 'frame.menu.contact' | translate }}
                </a>
            </li>
            <li class="nav-item" class="pointer" *ngIf="showZendeskLink">
                <a class="nav-item nav-link" href="https://flexportal.zendesk.com/" target="new">
                    <i-lucide name="circle-help" size="16" strokeWidth="1" class="mr-1"></i-lucide>
                    {{ 'frame.menu.help' | translate }}
                </a>
            </li>
            <li class="nav-item" class="pointer">
                <a class="nav-item nav-link" (click)="logout()">
                    <i-lucide name="log-out" size="16" strokeWidth="1" class="mr-1"></i-lucide>
                    {{ 'frame.menu.log-off' | translate }}
                </a>
            </li>
            <li class="nav-item">
                <span class="release-date" *ngIf="releaseDate">
                    <i-lucide name="git-branch" size="16" strokeWidth="1" class="mr-1"></i-lucide>
                    {{ releaseDate | date: 'EE d MMM' | capitalizeFirst | replace: '.':'' }}
                    {{ 'shared.at' | translate }}
                    {{ releaseDate | date: 'HH:mm' }}
                </span>
            </li>
        </ul>
    </div>
</nav>