<footer class="text-muted mx-1">
    <div class="company">
        <i-lucide name="building" size="20" strokeWidth="1" class="mr-1"></i-lucide>
        <span>{{ name }}</span>
        <span *ngIf="internetPortalName"> / {{ internetPortalName }}</span>
        <span *ngIf="organisationName"> / {{ organisationName }}</span>
    </div>
    <div class="phone">
        <i-lucide name="phone" size="20" strokeWidth="1" class="ml-3 mr-1"></i-lucide>
        <span>{{ telephone }}</span>
    </div>
    <div class="email">
        <i-lucide name="mail" size="20" strokeWidth="1" class="ml-3 mr-1"></i-lucide>
        <a [href]="'mailto:' + email">{{ email }}</a>
    </div>

    <div class="small-footer">
        <a [href]="'tel:' + telephone">
            <i-lucide name="phone" size="20" strokeWidth="1"></i-lucide>
        </a>
        <a [href]="'mailto:' + email">
            <i-lucide name="mail" size="20" strokeWidth="1"></i-lucide>
        </a>
    </div>
</footer>