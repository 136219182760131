<table>
  <tr>
    <td style="width: 30px;" *ngIf="backButtonCallback">
      <h3><a href="javascript:void(0)" (click)="backButtonCallback()">
          <i-lucide name="chevron-left" size="40" strokeWidth="2"></i-lucide>
        </a></h3>
    </td>

    <td>
      <div class="truncate-ellipsis mb-2">
        <h3>{{ locationHeader.translationKey | translate:locationHeader.params }}</h3>
      </div>
    </td>

    <td style="width: 30px;">
      <h3 class="ml-2 mr-2"><a href="javascript:void(0)" (click)="toggleMenuOpen()">
          <i-lucide name="menu" size="32" strokeWidth="3"></i-lucide>
        </a></h3>
    </td>
  </tr>
</table>