<div class="flexworker-info" [style.maxWidth]="maxWidthPx">
    <div class="name-and-male">
        <ng-container [ngSwitch]="flexworker?.gender">
            <i-lucide name="circle" class="female" size="16" strokeWidth="1" *ngSwitchCase="'0'"></i-lucide>
            <i-lucide name="circle" class="male" size="16" strokeWidth="1" *ngSwitchCase="'1'"></i-lucide>
        </ng-container>
        &nbsp;
        <flag-icon *ngIf="flexworker?.nationality" [country]="flexworker?.nationality | lowercase"></flag-icon>
        <span class="title" [class.large-title]="showLargeFullName">&nbsp;&nbsp;{{
            showFullNameReversed ? getFlexworkerProperty(['fullNameReversed']) : flexworker?.fullName
            }}</span>
    </div>
    <div class="statement-info">
        <div class="role" *ngIf="statement.jobTitle && showJobTitle">
            <i-lucide name="captions" size="16" strokeWidth="1"></i-lucide>&nbsp;{{ statement.jobTitle }}
        </div>
        <div class="company" *ngIf="statement.costCenter?.name && showCostCenter">
            <i-lucide name="factory" size="16" strokeWidth="1"></i-lucide>&nbsp;{{ statement.costCenter?.name || '?' }}
        </div>
        <div class="working-days" *ngIf="showWorkingDays">
            <i-lucide name="calendar-week" size="16" strokeWidth="1"></i-lucide>&nbsp;{{ statement.firstDay | date:
            'EEEE' }} -
            {{ statement.lastDay | date: 'EEEE' }}
        </div>
    </div>
</div>